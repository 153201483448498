/*
Template Name: Mediumish
Copyright: Sal, WowThemes.net, https://www.wowthemes.net
License: https://www.wowthemes.net/freebies-license/
*/

$base-color: rgb(252, 252, 252);
$highlight-blue: rgb(63, 135, 210);
$lightgray: rgb(230, 230, 230);

.feature-card .card-icon {
    text-align: center;
    font-size: 50px;
    padding: 20px;
}

.feature-card .card-icon img {
    margin-bottom: 0;
}

.feature-card .card-title {
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.25rem;
}

.feature-card .card-description {
    text-align: center;
    color: #cccccc;
}

.feature-card .card {
    padding: 35px;
}

.frontcard-title {
    font-weight: 400;
    text-align: left;
    font-size: 2.25rem;
    font-family: "Oswald", Arial, Helvetica, sans-serif;
    margin-bottom: 1.25rem;
}

.feature-title {
    font-weight: 400;
    text-align: left;
    font-size: 2rem;
    font-family: "Oswald", Arial, Helvetica, sans-serif;
    margin-bottom: 1rem;
}

.feature-card.feature-header-img {
    -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.30);
    -moz-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.30);
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.30);
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 0.5rem;
}

.feature-card.feature-header-img img {
    margin-bottom: 0;
}

.feature-subtitle {
    color: #666666;
}


.panel {
    min-height: 50vh;
    padding-bottom: 5rem;
    padding-top: 5rem;
}

.video-holder .btn.cta.play-button {
    background-color: rgba(255, 255, 255, 100);
    opacity: 0.8;
    border-radius: 20px;
}
.video-holder:hover .btn.cta.play-button {
    background-color: $highlight-blue;
    opacity: 1;
}

.pr-30 {
    padding-right: 30px;
}

.pl-30 {
    padding-left: 30px;
}

.panel.big {
    min-height: 80vh;
}

.padded-box {
    padding: 5%;
}

.padded-box-10 {
    padding: 10%;
}

.panel.smaller {
    min-height: 20vh;
}

body {
    background-color: $base-color;
    min-height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.bg-base {
    background-color: $base-color;
}

.bg-grey {
    background-color: rgb(90, 90, 90);
}

.bg-darkgrey {
    background-color: rgb(40, 40, 40);
}

.bg-darkgrey .card {
    background-color: rgb(70, 70, 70);
    color: white;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.30);
    border-radius: 8px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.bg-darkgrey .card:hover {
    border: 1px solid $highlight-blue;
}

.bg-darkgrey .panel-title {
    color: white;
    font-family: "Oswald", Arial, Helvetica, sans-serif;
    font-weight: 400;
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.panel-title {
    font-family: "Oswald", Arial, Helvetica, sans-serif;
    font-weight: 400;
    width: 100%;
    text-align: center;
}



.bg-lightgrey {
    background-color: $lightgray;
}

.bg-grid {
    background: url("/assets/images/grid_background.png");
    background-color: rgb(90, 90, 90);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120vw;
}


.vertical-center {
    margin: 0;
    position: absolute;
    left: 15%;
    bottom: 15%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  }


.btn.demo, .btn.trial {
    margin-top: 1px;
    font-size: 0.85rem;
    background-color: $highlight-blue;
    color: #eeeeee;
}

.btn.demo:hover, .btn.trial:hover {
    background-color: #5494d7;
}

.btn.secondary {
    margin-top: 0.5rem;
    font-size: 0.85rem;
    border: 1.5px solid  #5494d7;
    // color: #444444;
    color: $highlight-blue;
    width: 100%;
    // background-color: rgba(0, 0, 0, 0.05);
}

.btn.secondary:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-color: #5494d7;
}

.btn.trial {
    width: 100%;
}

.pricing-button {
    position: absolute;
    bottom: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
    left: 0;
    width: 100%;
}


.btn.cta {
    background-color: #dddddd;
    color: #333333;
}

.btn.cta.darker {
    background-color: #cccccc;
}

.btn.cta.darker:hover {
    background-color: #dddddd;
}


.btn.cta:hover {
    background-color:#e8e8e8;
}

ul.faq-list li {
    margin-bottom: 2rem;
}

.faq-question {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
}

.faq-answer {
    color: #666666;
}

.feature-card h2 {
    color: #111111;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
}

.bg-darkgrey .feature-card h2 {
    color: #ffffff;
}

.feature-card p {
    color: #666666;
    font-size: 0.9rem;
}

.bg-darkgrey .feature-card p {
    color: #cccccc;
}

i.button-icon {
    margin-left: 0.5rem;
}


.floating-video {
    width: 94%;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.30);
    -moz-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.30);
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.30);
}

.feature-card img {
    margin-bottom: 1em;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    // border: 5px solid rgb(70, 70, 70);
}

.panel.top {
    padding-top: 25px;
}

.page-title {
    margin-bottom: 1.5rem;
}
.page-title h1 {
    font-weight: 300;
    font-family: "Oswald", Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
}

.feature-group .card-group {
    margin-bottom: 30px;
}

.feature-group .card {
    padding: 30px;
}

  .frontcard {
      font-size: 1.1rem;
      font-weight: 300;
  }


#demo-request-title {
    font-family: "Oswald", Arial, Helvetica, sans-serif;
    font-size: 2rem;
    font-weight: 300;
}

.form-group.required label:after {
    content: " *";
    color: red;
}

.side-card {
    padding: 15px;
}

.alert-primary {
    color: #dddddd;
    background-color: #333333;
    border-color: #333333;
}

.alert-secondary {
    background-color: #e8e8e8;
}


.bio-pic {
    border-radius: 50%;
    max-width: 40%;
}

.bio-name {
    font-size: 1.2rem;
    font-weight: bold;
}

.bio-title {
    font-size: 0.9rem;
    color: #555555;
}

.bio {
    margin-bottom: 1.5rem;
}

.alert {
    margin-top: 1rem;
}

.social-link {
    margin-left: 10px;
}

.bio a {
    color: black;
}

.bio a:hover .social-link {
    color:$highlight-blue;
}


.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }



@media screen and (min-width:800px) {
    #video-modal .modal-dialog {
        max-width: 60vw;
    }

    #video-modal .modal-content {
        padding: 20px;
        position: relative;
    }

    #video-modal button.close {
        color: white;
        text-align: right;
        position: absolute;
        top: -30px;
        right: -20px;
    }
}

@media screen and (max-width:799px) {
    #video-modal .modal-dialog {
        max-width: 90vw;
    }

    #video-modal .modal-content {
        padding: 5px;
        position: relative;
    }

    #video-modal button.close {
        color: white;
        text-align: right;
        position: absolute;
        top: -30px;
        right: 10px;
    }
}

@media (max-width: 1199px) {
    .feature-card .card-icon {
        padding: 0px;
        text-align: left;
        float: left;
        width: 30%;
        white-space: nowrap;
        height: 100%;
    }

    .align-helper {
        display:inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .feature-card .card {
        display: table;
        clear: both;
    }

    .feature-card .card-icon img {
        // max-height: 100px;
        vertical-align: middle;
        margin-left: -15%;
    }

    .feature-card .card-title {
        width:65%;
        float: right;
        text-align: left;
        padding-left: 10px;
    }
    .feature-card .card-description {
        float: right;
        text-align: left;
        width: 65%;
        padding-left: 10px;
    }
}

.right-pad {
    padding-right: 30px;
}

.left-pad {
    padding-left: 30px;
}

// @media (min-width: 768px) {
//     .container, .container-md, .container-sm {
//         max-width: 820px;
//     }
// }

@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 90%;
    }
}

@media screen and (min-width:1500px) {
    .container {
        max-width:1260px;
    }
}
.mainheading {
    padding: 1rem 0rem;
}

a {
    color: $highlight-blue;
    transition: all 0.2s;
}

a:hover {
    color: black;
    text-decoration: none;
}

pre {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: #E3EDF3 1px solid;
    width: 100%;
    padding: 7px;
    font-family: monospace, sans-serif;
    font-size: .9rem;
    white-space: pre;
    overflow: auto;
    background: #fff;
    border-radius: 0px;
    line-height: 1.6;
    color: #333;
    margin-bottom: -rem;
}

.mediumnavigation {
    border-bottom: 1px solid #eeeeee;
    transition: top 0.2s ease-in-out;
    min-height: 80px;
}

.site-content {
    padding-top: 80px;
    transition: all 0.4s;
    background-color: $base-color;
    position: relative;
}

.alert.top-alert {
    margin-top: 0px;
    padding: 0.5em;
    text-align: center;
    color: black;
    font-size: 0.85em;
    border-color: $highlight-blue;
    border-radius: 0;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.col-form-label {
    padding-left: 0.25rem;
    font-size: 0.85rem;
    letter-spacing: 1px;
    color: #333333;
}


.custom-control-label {
    font-size: 0.85rem;
    padding-top: 0.1rem;
}


section.recent-posts {
    margin-bottom: 0;
}

.section-title h2 {
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 27px;
}

.section-title span {
    border-bottom: 1px solid rgba(0, 0, 0, .44);
    display: inline-block;
    padding-bottom: 20px;
    margin-bottom: -1px;
}

.article-post ol,
.article-post ul {
    margin-bottom: 1.5rem;
}

.article-post ol ol,
.article-post ul ul {
    list-style: disc;
    margin-bottom: 0rem;
}

@media (min-width:576px) {
    .card-columns.listfeaturedtag {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (min-width:992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.listfeaturedtag {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.listfeaturedtag .wrapthumbnail {
    height: 290px;
    flex: 0 0 auto;
    height: 100%;
}

.maxthumb {
    max-height: 300px;
    overflow: hidden;
}

.listfeaturedtag .card,
.card-footer {
    border: 0;
}

.listfeaturedtag .thumbnail {
    background-size: cover;
    height: 100%;
    display: block;
    background-position: 38% 22% !important;
    background-origin: border-box !important;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.listfeaturedtag .card-block {
    padding-left: 0;
}

.listfeaturedtag h2.card-title,
.listrecent h2.card-title {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.25;
}

.listfeaturedtag h4.card-text,
.listrecent h4.card-text {
    color: rgba(0, 0, 0, .44);
    font-size: 0.95rem;
    line-height: 1.6;
    font-weight: 400;
}

.wrapfooter {
    font-size: .8rem;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.author-thumb {
    width: 40px;
    height: 40px;
    margin-right: 13px;
    border-radius: 100%;
}

.post-top-meta {
    margin-bottom: 2rem;
}

.post-top-meta .author-thumb {
    width: 72px;
    height: 72px;
}

.post-top-meta.authorpage .author-thumb {
    margin-top: 40px;
}

.post-top-meta span {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, .44);
    display: inline-block;
}

.post-top-meta .author-description {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 0.95rem;
}

.toc ul {
    list-style: decimal;
    font-weight: 400;
}

.author-meta {
    flex: 1 1 auto;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

span.post-name,
span.post-date,
span.author-meta {
    display: inline-block;
}

span.post-date,
span.post-read {
    color: rgba(0, 0, 0, .44);
}

span.post-read-more {
    align-items: center;
    display: inline-block;
    float: right;
    margin-top: 8px;
}

span.post-read-more a {
    color: rgba(0, 0, 0, .44);
}

span.post-name a,
span.post-read-more a:hover {
    color: rgba(0, 0, 0, .8);
}

.dot:after {
    content: "·";
    margin-left: 3px;
    margin-right: 3px;
}

.mediumnavigation .form-control {
    font-size: 0.8rem;
    border-radius: 30px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.09);
    min-width: 180px;
}

.mediumnavigation .form-inline {
    margin-left: 15px;
}

.mediumnavigation .form-inline .btn {
    margin-left: -50px;
    border: 0;
    border-radius: 30px;
    cursor: pointer;
}

.mediumnavigation .form-inline .btn:hover,
.mediumnavigation .form-inline .btn:active {
    background: transparent;
    color: green;
}

.mediumnavigation .navbar-brand {
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
}

.mediumnavigation .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.08);
    margin: 0 0 0;
}

.mediumnavigation .nav-item,
.dropdown-menu {
    font-size: 0.9rem;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.9);
}

.dropdown:hover>.dropdown-menu {
    display: block;
}

.dropdown-menu {
    left: -0.5rem;
    background-color: $base-color;
}

.dropdown-item {
    padding: 0.25rem 1.5rem;
    color: rgba(0, 0, 0, 0.7);
}

.dropdown-item:hover {
    background-color: rgb(240, 240, 240);
    color: rgba(0, 0, 0, 0.9);
}

.dropdown-item:active {
    color: rgba(0, 0, 0, 0.9);
}

.mediumnavigation .search-icon {
    margin-left: -40px;
    display: inline-block;
    margin-top: 3px;
    cursor: pointer;
}

.mediumnavigation .navbar-brand img {
    max-height: 50px;
    margin-right: 5px;
}

.mainheading h1.sitetitle {
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    font-size: 3.2rem;
    margin-bottom: 2rem;
}

.cta.lead-cta {
    background-color: $highlight-blue;
    color: #eeeeee;
}

.cta.lead-cta:hover {
    background-color: #5494d7;
}

.cta.lead-cta.secondary {
    background-color: $base-color;
    color:rgba(0, 0, 0, .8);
    border: 1px solid $highlight-blue;
}

.cta.lead-cta.secondary:hover {
    background-color: rgb(230, 230, 230);
}

.mainheading h1.posttitle {
    font-weight: 700;
    margin-bottom: 1rem;
}

footer {
    margin-top: auto;
    border-top: 3px solid rgba(0, 0, 0, .1) !important;
    padding-top: 3rem;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, .44);
    width: 100%;
    background: $lightgray;
}

.footer-icon {
    font-size: 1.6rem;
}

.footer-icon a {
    color: rgba(0, 0, 0, .8);
}

.footer-icon a:hover i {
    color: $highlight-blue;
}

.footer-icon .list-inline .list-inline-item {
    width: 40px;
}

.footer-iograft-icon {
    max-width: 72px;
    margin-left: auto;
    margin-right: auto;
}

.footer-logo {
    max-width: 180px;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
}

.footer-title {
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
    // font-family: "Oswald", sans-serif;
}

.footer-nav {
    font-size: 1rem;
    padding-left: 0;
    list-style-type: none;
    color: rgb(80, 80, 80);
    margin-bottom: 2.5rem;
}

.footer-nav a {
    color: rgb(80, 80, 80);
}

.footer-nav a:hover {
    color: black;
}


#sub-footer {
    border-top: 3px solid rgba(0, 0, 0, .15) !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: $base-color;
}

.link-dark {
    color: rgba(0, 0, 0, .8);
}

.article-post {
    font-family: Merriweather;
    font-size: 1.1rem;
    line-height: 1.84;
    color: rgba(0, 0, 0, .8);
}

blockquote {
    border-left: 4px solid #00ab6b;
    padding: 0 20px;
    font-style: italic;
    color: rgba(0, 0, 0, .5);
}

.article-post p,
.article-post blockquote {
    margin: 0 0 1.5rem 0;
}

.featured-image {
    display: block;
    margin-bottom: 1.5rem;
}

.share {
    text-align: center;
}

.share p {
    margin-bottom: 10px;
    font-size: 0.95rem;
}

.share ul li {
    display: inline-block;
    margin-bottom: 9px;
}

.share ul {
    padding-left: 0;
    margin-left: 0;
}

.share ul li i.fa {
    border: 1px solid #ddd;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
}

.svgIcon {
    vertical-align: middle;
}

.sticky-top-offset {
    top: 100px;
}

@media (min-width:1024px) {
    .share ul li {
        display: block;
    }
}

@media (max-width:999px) {
    .alertbar {
        position: relative !important;
        margin-bottom: 20px;
        margin-top: 20px;
        box-shadow: none !important;
        padding-right: 14px !important;
    }

    #comments {
        margin-right: 15px;
    }

    .alertbar form {
        margin-top: 20px;
    }

    .alertbar span,
    .alertbar form {
        display: block;
    }

    .alertbar input[type="submit"] {
        border-radius: 3px !important;
    }

    .alertbar input[type="email"] {
        margin-right: 0px !important;
        display: block;
        border-right: 1px solid #ddd !important;
        margin-bottom: 10px;
    }

    .listfeaturedtag .card {
        height: auto;
    }

    .listfeaturedtag .wrapfooter {
        position: relative;
        margin-top: 30px;
    }

    .listfeaturedtag .card-block {
        padding: 20px;
    }

    .footer {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@media (max-width:1024px) {
    .post-top-meta .col-md-10 {
        text-align: center;
    }
}

@media (max-width:767px) {
    .post-top-meta.authorpage {
        text-align: center;
    }
}

.share,
.share a {
    color: rgba(0, 0, 0, .44);
    fill: rgba(0, 0, 0, .44);
}

.graybg {
    background-color: #fafafa;
    padding: 40px 0 46px;
    position: relative;
}

.listrelated .card {
    box-shadow: 0 1px 7px rgba(0, 0, 0, .05);
    border: 0;
}

ul.tags {
    list-style: none;
    padding-left: 0;
    margin: 0 0 3rem 0;
}

ul.tags li {
    display: inline-block;
    font-size: 0.9rem;
}

ul.tags li a {
    background: rgba(0, 0, 0, .05);
    color: rgba(0, 0, 0, .6);
    border-radius: 3px;
    padding: 5px 10px;
}

ul.tags li a:hover {
    background: rgba(0, 0, 0, .07);
    text-decoration: none;
}

.margtop3rem {
    margin-top: 3rem;
}

.sep {
    height: 1px;
    width: 20px;
    background: #999;
    margin: 0px auto;
    margin-bottom: 1.2rem;
}

.btn.follow {
    border-color: #02B875;
    color: #1C9963;
    padding: 3px 10px;
    text-align: center;
    border-radius: 999em;
    font-size: 0.85rem;
    display: inline-block;
}

.btn.subscribe {
    background-color: #1C9963;
    border-color: #1C9963;
    color: rgba(255, 255, 255, 1);
    fill: rgba(255, 255, 255, 1);
    border-radius: 30px;
    font-size: 0.85rem;
    margin-left: 10px;
    font-weight: 600;
    text-transform: uppercase;
}

.post-top-meta .btn.follow {
    margin-left: 5px;
    margin-top: -4px;
}

.alertbar {
    box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, .0785);
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    padding: 14px 0;
    z-index: 1;
    display: none;
}

.alertbar form {
    display: inline-block;
}

.alertbar input[type="email"] {
    font-size: 0.85rem;
    padding: 3px 5px 3px 10px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: 1px solid #ddd;
    border-right: 0;
    margin-right: -10px;
    height: 34px;
    letter-spacing: 0.5px;
    margin-left: 5px;
}

.alertbar input[type="submit"] {
    background-color: #1C9963;
    border: 1px solid #1C9963;
    color: rgba(255, 255, 255, 1);
    fill: rgba(255, 255, 255, 1);
    font-size: 0.85rem;
    border-radius: 0;
    padding: 4px 10px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    font-weight: 600;
    height: 34px;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.form-control::-webkit-input-placeholder {
    color: rgba(0, 0, 0, .5);
}

.form-control:-moz-placeholder {
    color: rgba(0, 0, 0, .5);
}

.form-control::-moz-placeholder {
    color: rgba(0, 0, 0, .5);
}

.form-control:-ms-input-placeholder {
    color: rgba(0, 0, 0, .5);
}

.form-control::-ms-input-placeholder {
    color: rgba(0, 0, 0, .5);
}

.authorpage h1 {
    font-weight: 700;
    font-size: 30px;
}

.post-top-meta.authorpage .author-thumb {
    float: none;
}

.authorpage .author-description {
    font-size: 1rem;
    color: rgba(0, 0, 0, .6);
}

.post-top-meta.authorpage .btn.follow {
    padding: 7px 20px;
    margin-top: 10px;
    margin-left: 0;
    font-size: 0.9rem;
}

.graybg.authorpage {
    border-top: 1px solid #f0f0f0;
}

.authorpostbox {
    width: 760px;
    margin: 0px auto;
    margin-bottom: 1.5rem;
    max-width: 100%;
}

.authorpostbox .img-thumb {
    width: 100%;
}

.sociallinks {
    margin: 1rem 0;
}

.sociallinks a {
    background: #666;
    color: #fff;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    line-height: 22px;
    border-radius: 50%;
    font-size: 12px;
}

#comments {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.article-post .h1,
.article-post .h2,
.article-post .h3,
.article-post .h4,
.article-post .h5,
.article-post .h6,
.article-post h1,
.article-post h2,
.article-post h3,
.article-post h4,
.article-post h5,
.article-post h6 {
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.article-post img.shadow {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
}

.layout-page .article-post {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
}

.layout-page .article-post p {
    margin-bottom: 1rem;
}

img {
    max-width: 100%;
}

.bottompagination span.navigation {
    display: block;
    font-size: 0.93rem;
    padding: 15px 0 0 0;
    text-align: center;
    margin-bottom: 0rem;
    color: #999;
    border-top: 1px solid #ddd;
}

.pointerup {
    margin-bottom: -17px;
    margin-left: 49%;
    font-size: 30px;
}

.pointerup i.fa {
    color: #eaeaea;
}

.bottompagination span.navigation i {
    display: inline-block;
}

span.navigation {
    display: inline-block;
    font-size: 0.93rem;
    font-weight: 700;
    text-align: center;
}

.pagination {
    display: block;
}

iframe {
    max-width: 100%;
}

.mb-30px {
    margin-bottom: 30px;
}

.flex-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
}

@media (min-width: 768px) {
    .flex-md-unordered {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }

    .flex-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
}

@media (max-width: 768px) {
    .share {
        margin-top: 30px;
    }
}

.card .img-fluid {
    width: 100%;
}

.sticky-top-80 {
    top: 80px;
}

.spoiler {
    color: transparent;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    transition: all .4s;
    cursor: pointer;
    position: relative;
}

.spoiler:after {
    position: absolute;
    opacity: 0;
    content: 'Click to reveal spoiler';
    top: 45%;
    left: calc(50% - 100px);
    text-shadow: none;
    background: #222;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    line-height: 1;
    padding: 2px 3px;
    width: 150px;
    font-family: Arial;
    text-align: center;
    border-radius: 3px;
    transition: all .4s;
}

.spoiler:hover:after {
    opacity: 1;
}

/** Lazy img **/
.lazyimg {
    display: block;
    border: 0 none;
    opacity: 1;
    transition: opacity .25s;
    background: #f2f2f2;
    outline: 0 none;
}

.lazyimg[data-src],
.lazyimg[data-srcset] {
    opacity: 0;
    transition: opacity .25s;
}


.form-control.is-valid, .was-validated .form-control:valid {
    border-color: #ced4da;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%233f87d2' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
}


.custom-control-input.is-valid~.custom-control-label, .was-validated .custom-control-input:valid~.custom-control-label {
    color: #212529;
}


.custom-control-input.is-valid:checked~.custom-control-label::before, .was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: rgb(63, 135, 210);
    background-color: rgb(63, 135, 210);
}

.front-subtitle {
    font-size: 1.4em;
    color:rgb(63, 135, 210);
}

.pricing-card-wrap {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 30px;
}

.pricing-card {
    padding: 40px;
    margin-bottom: 30px;
    border-radius: 8px;
}

.bg-darkgrey .pricing-card:hover {
    border: 1px solid rgba(0, 0, 0, 0) !important;
}

.pricing-card ul {
    padding-left: 20px;
}

.pricing-card li {
    list-style: none;
    padding-left: 5px;
    padding-bottom: 10px;
    position: relative;
}

.pricing-card li.raw {
    list-style: disc;
}

.pricing-card li i {
    position: absolute;
    left: -22px;
    top: 4px;
    margin-right: 10px;
    color:rgb(100, 100, 100);
}

.pricing-card h3 {
    font-family: "Oswald", Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 1.8em;
}

.plan-price {
    font-size: 2.4em;
    font-weight: bold;
    padding-right: 0.5em;
    color: $highlight-blue;
    display: block;
}

.plan-subtitle {
    color: rgb(100, 100, 100);
    font-size: 0.95em;
    text-align: center;
    min-height: 5em;
}


.price {
    margin-top: 1.5em;
    margin-bottom: 3em;
}

.price.with-prefix {
    margin-top: 0.5em;
}

.price-prefix {
    position: relative;
    top: -0.5em;
}

.price-note {
    // color:rgb(200, 200, 200);
}

.pricing-subtitle {
    // color: rgb(75, 162, 255);
    color: $highlight-blue;
}


.dimmed {
    position: relative;
}

.dimmed:before {
    content: " ";
    z-index: 10;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.7);
}

.centered-container {
    display: block;
    position: absolute;
    text-align: center;
    z-index: 11;
    width: 100%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0;
}

.padded-box .centered-container {
    width: 90%;
}

.expandable[aria-expanded="true"] .fa-angle-down {
    transform: rotate(180deg);
    padding-right: 10px;
}

.expandable[aria-expanded="false"] .fa-angle-down {
    padding-left: 10px;
}

.card-header:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.infoCard {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
}

#accountCardIcon {
    position: absolute;
    font-size: 5rem;
    top: 40%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15%;
}


.downloadBox {
    text-align: left;
    padding: 1rem;
    background-color: white;
    border-color: #cccccc;
    color: $highlight-blue;
    margin: 0.5rem;
}

.downloadBox:hover {
    background-color: rgb(252, 252, 252);
}

.downloadInfo {
    float: right;
    color: black;
}

.downloadPlaceholder {
    width: 100%;
    text-align: center;
    color:rgb(100, 100, 100);
    margin-bottom: 0px;
    padding: 1.5rem;
}

.highlight-color {
    color: $highlight-blue;
}

.subscribe-step-icon {
    font-size: 1.2rem;
    margin-right: 1rem;
}

.subscribe-step-title {
    font-weight: bold;
    font-size: 1.15rem;
}

.subscribe-step-info {
    font-size: 1rem;
    color: rgb(90, 90, 90);
    float: right;
}


.locked-step {
    pointer-events: none;
    color: #999999 !important;
}

.paddle-frame-inline html {
    overflow: hidden;
}


a:hover {
    cursor: pointer;
}

.info-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.collapse-title {
    font-weight: bold;
}
